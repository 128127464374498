body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

.core {
  transform: translateY(-50%) !important;
}

.core--left {
  transform: translate(-100%, -50%) !important;
}

.react-colorful { 
  width: 100% !important;
}